import createApp from '@haaretz/s-next-create-app';
import merriweather, { MERRIWEATHER_VAR } from '@haaretz/s-next-font-merriweather';
import PpPageLayout from '@haaretz/s-pp-page-layout';
import config from 'config';

import '../../styles/globals.css';

const PromotionPageHdcApp = createApp({
  config,
  Layout: PpPageLayout,
  fonts: [[merriweather, MERRIWEATHER_VAR]],
});
export default PromotionPageHdcApp;
